<template>
    <el-table v-show="tableData.length > 0" v-loading="$wait.is('loading.services')" :data="tableData" class="w-full" element-loading-spinner="el-custom-spinner">
        <el-table-column prop="color" width="30">
            <template slot-scope="scope">
                <div class="flex h-5 w-5 rounded" :style="'background:' + scope.row.color" />
            </template>
        </el-table-column>

        <el-table-column prop="name" :label="$t('services.name')" sortable :show-overflow-tooltip="true" />

        <el-table-column prop="category.name" :label="$t('services.category')" sortable>
            <template slot-scope="scope">
                {{ scope.row.category && scope.row.category.name }}
            </template>
        </el-table-column>

        <el-table-column prop="price" sortable :label="$t('services.price')" width="110" align="right">
            <template slot-scope="scope">
                {{ intToMoneyFormat(scope.row.price) }}
            </template>
        </el-table-column>

        <el-table-column prop="price_type" :label="$t('services.price_type')" sortable width="130">
            <template slot-scope="scope">
                {{ $t(`services.${scope.row.price_type}`) }}
            </template>
        </el-table-column>

        <!-- <el-table-column prop="timbank_volume" label="Timbank" sortable width="100" align="center">
            <template slot-scope="scope">
                <template v-if="scope.row.timbank_volume">
                    {{ normalizeTime(scope.row.timbank_volume) }}
                </template>
                <template v-else>
                    -
                </template>
            </template>
        </el-table-column> -->

        <el-table-column prop="rut" label="RUT" sortable width="70">
            <template slot-scope="scope">
                {{ scope.row.rut ? $t(`common.yes`) : $t(`common.no`) }}
            </template>
        </el-table-column>

        <el-table-column prop="rot" label="ROT" sortable width="70">
            <template slot-scope="scope">
                {{ scope.row.rot ? $t(`common.yes`) : $t(`common.no`) }}
            </template>
        </el-table-column>

        <el-table-column width="75">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.edit')" placement="left">
                        <el-button type="primary" size="mini" :disabled="!userCan('update services')" @click="handleEdit(scope.row.id)">
                            <fa-icon :icon="['far', 'edit']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                        <el-button type="danger" size="mini" :disabled="!userCan('delete services')" @click="handleDelete(scope.row.id)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        tableData: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        handleEdit(id) {
            this.$emit('handleEdit', id);
        },

        handleDelete(id) {
            this.$emit('handleDelete', id);
        },
    },
};
</script>
